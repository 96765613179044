.page {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-secret {
    background-image: url('../public/images/page2.jpeg');
}
.show-secret {
    background-image: url('../public/images/final_page.jpeg');
    background-position: top;
}
.page-content {
    position: relative;
    min-width: 500px;
    max-width: 500px;
    padding: 20px;
    z-index: 2;
}
.add-secret .page-content:before {
    position: absolute;
    content: '';
    background-color: black;
    height: 2px;
    width: 50px;
    bottom: 30px;
    z-index: 3;
    left: 0;
}
.add-secret .page-content:after {
    position: absolute;
    content: '';
    background-color: black;
    height: 2px;
    width: 50px;
    top: 30px;
    z-index: 3;
    right: 0;
}
.show-secret .page-content:before {
    position: absolute;
    content: '';
    background-color: black;
    height: 50px;
    width: 2px;
    bottom: 0px;
    z-index: 3;
    left: 30px;
}
.show-secret .page-content:after {
    position: absolute;
    content: '';
    background-color: black;
    height: 50px;
    width: 2px;
    top: 0px;
    z-index: 3;
    right: 30px;
}

.underlay {
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.footer {
    position: absolute;
}
.large p {
    font-size: 50px;
}
form {
    display: flex;
    row-gap: 10px;
    max-width: 400px;
}
.the-secret {
    margin: 35px 0;
}
@media(max-width: 900px) {
    .page-content {
        min-width: unset;
        max-height: unset;
        margin: 20px;
    }
    .large p {
        font-size: 40px;
    }
}
@media(max-width: 600px) {
    form {
        display: flex;
        row-gap: 10px;
        flex-wrap: wrap;
    }
}
@media(max-width:450px) {
    .large p {
        font-size: 35px;
    }
    .page {
        height: unset;
        min-height: 100vh;
        flex-direction: column;
        justify-content: space-between;
    }
    .footer {
        position: relative;
    }
}
