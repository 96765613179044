@import url( 'https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&display=swap');
body {
    margin: 0;
    font-family: 'antonio';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
p {
    margin: 0;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.start-screen {
    background-image: url('../public/images/start.jpeg');
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
#psst {
    width: 100vw;
}
#psst:hover {
    cursor: pointer;
}
.add {
    background-image: url('../public/images/page2.jpeg');
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.final {
  background-image: url('../public/images/final_page.jpeg');
  height: 100vh;
  background-size: cover;
  background-position: top;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-section-underlay {
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
}
.add-section {
    width: 60vw;
    height: 50vh;
    position: relative;
    background-color: transparent;
}
.content {
    position: relative;
    z-index: 2;
    margin-left: 35px;
}
.font-45 {
    font-size: 45px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 25px ;
}
.large {
    font-size: 50px;
    text-transform: uppercase;
    margin: 0;
}
form{
  margin-top: 25px
}
.input {
    color: white;
    background-color: black;
    border: none;
    font-size: 16px;
    padding: 10px;
    width: 100%;
    max-width: 400px;
}
button[type=submit]{
  background-color: #53B0D8;
  color: white;
  border: none;
  padding: 0;
  height: 40px;
  position: relative;
  padding:  10px;
  box-sizing: content-box;
  width: 160px;
}
button[type=submit].submit-secret, .input{
  height: 50px;
 
}
button[type=submit]:hover{
  cursor: pointer;
}
.bottom-text p{
  float: right;
  margin-top: 20px;
  text-transform: uppercase;
}
.footer{
  position: absolute;
  bottom: 0;
  background-color: black;
  color: white;
  width: 100%;
  /* padding: 10px; */
  padding-bottom: 10px;
  overflow: hidden;
  text-align: center;
  padding-top: 10px;
  z-index: 2;
  padding-left:15px;
  padding-right: 15px;
  box-sizing: border-box;
}
button:hover{
  cursor: pointer;
}
.last-page.bottom-text button, .reset-button{
  background-color: #53B0D8;
  color: white;
  border: none;
  padding: 0;
  height: 40px;
  position: relative;
  bottom: 2px;
  padding: 0px 10px;
  display: block;
  margin-left: auto;
  margin-top:15px;
  z-index:5;

}
.reset-button{
  margin-left: 20px;
}
.hidden-page .reset-button{
  margin-left: 0px;
}
.hidden-link{
  position: absolute;
  height: 50px;
  width: 50px;
  top: 0;
  left: 0;
}
.hidden-page {
  background-image: url('../public/images/hidden_page.jpeg');
  height: 100vh;
  background-size: cover;
  background-position: top;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.hidden-inner{
  width: 50vw;
  height: 100%;
  
  position:absolute;
  left: 0;

}
.hidden-inner h1{
  color: white;
  text-transform: uppercase;
  font-size: 90px;
}
.hidden-inner p{
  color: white;
  font-size: 35px;
}
.hidden-inner p span{
  display: block;
  margin-top: 30px
}
.hidden-page .background-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: .4;
  z-index: 1;
}
.hidden-page-content{
  position: relative;
    z-index: 2;
    margin: 60px;
}
@media(max-width:1200px) {
  .hidden-page {
    background-position: 30%;
  }
  .hidden-inner{
    width: 70vw;
  }
  .hidden-inner h1{
    color: white;
    text-transform: uppercase;
    font-size: 60px;
  }
  .hidden-inner p{
    color: white;
    font-size: 25px;
  }
  .hidden-page .footer{
    position: absolute;
  }
}
@media(max-width:600px) {
  
.large p {
  font-size: 35px;
}
  .hidden-page {
    background-position: 30%;
    height: unset;
    min-height: 100vh;;
    flex-direction: column;
  }
  .hidden-inner{
    width: 70vw;
    position: relative;
    margin-right: auto;
    margin-bottom: 35px;
    height: 100%;
    min-height: 80vh;
  }
  .hidden-page .background-overlay {
    padding-bottom: 35px;
  }
  .hidden-page .footer {
    position: relative;
    margin-top: auto;
}
  .hidden-inner h1{
    color: white;
    text-transform: uppercase;
    font-size: 50px;
  }
  .hidden-inner p{
    color: white;
    font-size: 20px;
  }

}